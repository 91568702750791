import React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'

const BreadCrumb = ({title}) => {
  return (
    <>
      <div className="breadcumb-wrapper " style={{background: 'url(assets/images/aboutus/10040.jpg)', backgroundPosition: 'center', backgroundSize: 'cover'}}>
        <div className="container z-index-common">
            <div className="breadcumb-content">
                <h1 className="breadcumb-title">{title}</h1>
                <ul className="breadcumb-menu">
                    <li><NavLink to="/">Home</NavLink></li>
                    <FaChevronRight className='text-white'/>
                    <li>{title}</li>
                </ul>
            </div>
        </div>
    </div>
    </>
  )
}

export default BreadCrumb
