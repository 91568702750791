import React from 'react'
import { FaTimes, FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaSearch, FaBars, FaArrowRight, FaEnvelope, FaPaperPlane, FaCopyright, FaChevronRight } from "react-icons/fa";
import { FaCalendarDays, FaCartShopping, FaLocationDot, FaMobileButton } from "react-icons/fa6";
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <footer className="footer-wrapper footer-layout5" style={{background: "#000"}}>
        <div className="widget-area">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-md-6 col-xl-5">
                        <div className="widget footer-widget">
                            <h3 className="widget_title">About Restaurant</h3>
                            <div className="th-widget-about">
                                <p className="about-text">BURGITOS  is a premium fast-food establishment committed to crafting mouthwatering burgers using high-quality ingredients. Our diverse menu caters to various tastes, offering classic  burgers, innovative chicken options, and vegetarian delights. With a focus on flavor and freshness, we strive to provide a satisfying culinary experience for burger enthusiasts. Visit us for a fusion of taste and quality in every bite!</p>
                                <div className="th-social d-flex">
                                    <a className="d-flex align-items-center justify-content-center m-2" href="https://www.facebook.com/"><FaFacebookF /></a>
                                    <a className="d-flex align-items-center justify-content-center m-2" href="https://www.twitter.com/"><FaTwitter /></a>
                                    <a className="d-flex align-items-center justify-content-center m-2" href="https://www.linkedin.com/"><FaLinkedinIn /></a>
                                    <a className="d-flex align-items-center justify-content-center m-2" href="https://www.instagram.com/"><FaInstagram /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-auto">
                        <div className="widget widget_nav_menu footer-widget">
                            <h3 className="widget_title">Our Menu</h3>
                            <div className="menu-all-pages-container">
                                <ul className="menu">
                                    <li><NavLink to="/menu/burger"><FaChevronRight />Burger</NavLink></li>
                                    <li><NavLink to="/menu/wraps"><FaChevronRight />Wraps</NavLink></li>
                                    <li><NavLink to="/menu/sides"><FaChevronRight />Sides</NavLink></li>
                                    <li><NavLink to="/menu/fries-and-shakes"><FaChevronRight />Fries & Shakes</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-6 col-xl-auto">
                        <div className="widget footer-widget">
                            <h3 className="widget_title">Recent Posts</h3>
                            <div className="recent-post-wrap">
                                <div className="recent-post">
                                    <div className="media-img">
                                        <NavLink to="#"><img src="assets/img/update_2/blog/recent-post-2-1.jpg" alt="Blog Image"/></NavLink>
                                    </div>
                                    <div className="media-body">
                                        <h4 className="post-title"><NavLink className="text-inherit" to="#">10 Reasons To Do A Digital Detox Challenge</NavLink></h4>
                                        <div className="recent-post-meta">
                                            <NavLink to="#"><FaCalendarDays/>21 June, 2023</NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="recent-post">
                                    <div className="media-img">
                                        <NavLink to="#"><img src="assets/img/update_2/blog/recent-post-2-2.jpg" alt="Blog Image"/></NavLink>
                                    </div>
                                    <div className="media-body">
                                        <h4 className="post-title"><NavLink className="text-inherit" to="#">New Restaurant Town Our Ple Award Contract</NavLink></h4>
                                        <div className="recent-post-meta">
                                            <NavLink to="#"><FaCalendarDays/>22 June, 2023</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-md-6 col-xl-auto">
                        <div className="widget footer-widget">
                            <h3 className="widget_title">Contact Now</h3>
                            <div className="th-widget-contact">
                                <div className="info-box d-flex align-items-center">
                                    <div className="info-box_icon d-flex align-items-center justify-content-center">
                                        <FaLocationDot/>
                                    </div>
                                    <p className="info-box_text m-0" >S-34,Second floor, Cross River Mall,Delhi-110032</p>
                                </div>
                                <div className="info-box d-flex align-items-center">
                                    <div className="info-box_icon d-flex align-items-center justify-content-center">
                                        <FaMobileButton/>
                                    </div>
                                    <p className="info-box_text m-0">
                                        <a href="tel:+918800816141" className="info-box_link">+91-8800816141</a>
                                    </p>
                                </div>
                                <div className="info-box d-flex align-items-center">
                                    <div className="info-box_icon d-flex align-items-center justify-content-center">
                                        <FaEnvelope/>
                                    </div>
                                    <p className="info-box_text m-0">
                                        <a href="mailto:info@burgito.com" className="info-box_link">info@burgitos.com</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-wrap">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-lg-6">
                        <p className="copyright-text">Copyright <FaCopyright/> 2024 <NavLink to="/">Burgito</NavLink>. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </>
  )
}

export default Footer
