import React, { useState, useEffect } from 'react';
import { FaTimes, FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaSearch, FaBars, FaArrowRight } from "react-icons/fa";
import { FaCartShopping, FaLocationDot } from "react-icons/fa6";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NavLink } from 'react-router-dom';

const Header = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const [searchVisible, setSearchVisible] = useState(false);
    const [isSticky, setIsSticky] = useState(false);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    const toggleSearch = () => {
        setSearchVisible(!searchVisible)
    }

    const handleOutsideClick = (e) => {
        if (menuVisible) {
            setMenuVisible(false);
        }
    };

    const handleScroll = () => {
        const topPos = window.scrollY;
        if (topPos > 500) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };

    window.addEventListener('scroll', handleScroll);


    return (
        <>
            <div className={`th-menu-wrapper ${menuVisible ? 'th-body-visible' : ''}`} onClick={handleOutsideClick}>
                <div className="th-menu-area text-center">
                    <button className="th-menu-toggle" onClick={toggleMenu}>
                        <FaTimes />
                    </button>
                    <div className="mobile-logo">
                        <NavLink to="/">
                            <LazyLoadImage className='img-fluid'  style={{maxWidth: '135px'}} src="assets/img/logo.png" alt="Burgito" />
                        </NavLink>
                    </div>
                    <div className="th-mobile-menu">
                        <ul>
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>
                                <NavLink to="/about-us">Company</NavLink>
                            </li>
                            <li>
                                <NavLink to="/contact-us">Become Franchise</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={`popup-search-box d-none d-lg-block ${searchVisible ?' show' : ''}`}>
                <button className="searchClose" onClick={toggleSearch}><FaTimes/></button>
                <form action="#">
                    <input type="text" placeholder="What are you looking for?"/>
                        <button type="submit"><FaSearch /></button>
                </form>
            </div>

            <header className='th-header header-layout5'>
                <div className="header-top">
                    <div className="container">
                        <div className="row justify-content-center justify-content-lg-between align-items-center">
                            <div className="col-auto d-none d-lg-block">
                                {/* <p className="header-notice">Free Delivery on all orders Over $50</p> */}
                            </div>
                            <div className="col-auto">
                                <div className="header-links">
                                    <ul>
                                        <li>
                                            <div className="header-social">
                                                <a href="https://www.facebook.com/"><FaFacebookF /></a>
                                                <a href="https://www.twitter.com/"><FaTwitter /></a>
                                                <a href="https://www.linkedin.com/"><FaLinkedinIn /></a>
                                                <a href="https://www.instagram.com/"><FaInstagram /></a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`sticky-wrapper ${isSticky ? 'sticky' : ''}`}>
                    <div className="menu-area">
                        <div className="container" >
                            <div className="d-flex align-items-center justify-content-between ">
                                <div className="col-auto">
                                    <div className="header-logo p-0">
                                        <NavLink to="/" className=' d-flex align-items-center' >
                                            <LazyLoadImage className='img-fluid ml-10' style={{maxWidth: '135px'}} src="assets/img/logo.png" alt="Burgito" />
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="col-auto me-xl-auto">
                                    <nav className="main-menu d-none d-lg-inline-block">
                                        <ul>
                                            <li>
                                                <NavLink to="/">Home</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/about-us">Company</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/contact-us">Become Franchise</NavLink>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="col-auto">
                                    <div className="header-button">
                                        <button type="button" className="th-menu-toggle d-inline-block d-lg-none" onClick={toggleMenu}><FaBars /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="logo-bg"></div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
