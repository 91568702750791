import React, { useEffect } from 'react';

const Preloader = () => {
    useEffect(() => {
        // Check if the preloader exists
        const preloader = document.querySelector(".preloader");
        if (preloader) {
            // Attach click event listener to preloaderCls buttons
            const preloaderButtons = document.querySelectorAll(".preloaderCls");
            preloaderButtons.forEach(button => {
                button.addEventListener("click", function (e) {
                    e.preventDefault();
                    preloader.style.display = "none";
                });
            });
        }
    }, []);

    return (
        <div className="preloader">
            <button className="th-btn style3 preloaderCls">Cancel Preloader</button>
            <div className="preloader-inner">
                <span className="loader"></span>
            </div>
        </div>
    );
}

export default Preloader;
