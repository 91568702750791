import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight, FaInstagram, FaStar } from "react-icons/fa";
import Slider from "react-slick";

const GalleryThumbArea = () => {
  const settings = {
    dots: false,
    fade: false,
    arrows: false,
    speed: 1000,
    autoplay: true,
    infinite: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          arrows: false,
          dots: false,
          slidesToShow: 8,
          centerMode: false,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 1400,
        settings: {
          arrows: false,
          dots: false,
          slidesToShow: 7,
          centerMode: false,
          centerPadding: 0,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          dots: false,
          slidesToShow: 5,
          centerMode: false,
          centerPadding: 0,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          dots: false,
          slidesToShow: 4,
          centerMode: false,
          centerPadding: 0,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: false,
          slidesToShow: 3,
          centerMode: false,
          centerPadding: 0,
        },
      },
      {
        breakpoint: 576,
        settings: {
          arrows: false,
          dots: false,
          slidesToShow: 2,
          centerMode: false,
          centerPadding: 0,
        },
      },
    ],
  };
  return (
    <>
      <div className="gallery-thumb-area">
        <div
          className="row gx-10 th-carousel"
          id="gallerySlide1"
          data-slide-show="8"
          data-ml-slide-show="7"
          data-lg-slide-show="6"
          data-md-slide-show="5"
          data-sm-slide-show="4"
          data-xs-slide-show="3"
          data-focuson-select="false"
        >
          <Slider {...settings}  >
            <div className="col-auto">
              <div className="gallery-thumb">
                <img
                  src="assets/images/galleryviewimages/img1.webp"
                  alt="Gallery Image"
                />
                <a
                  href="assets/images/galleryviewimages/img1.webp"
                  className="gallery-btn popup-image"
                >
                  <FaInstagram/>
                </a>
              </div>
            </div>
            <div className="col-auto">
              <div className="gallery-thumb">
                <img
                  src="assets/images/galleryviewimages/img2.webp"
                  alt="Gallery Image"
                />
                <a
                  href="assets/images/galleryviewimages/img2.webp"
                  className="gallery-btn popup-image"
                >
                  <FaInstagram/>
                </a>
              </div>
            </div>
            <div className="col-auto">
              <div className="gallery-thumb">
                <img
                  src="assets/images/galleryviewimages/img3.webp"
                  alt="Gallery Image"
                />
                <a
                  href="assets/images/galleryviewimages/img3.webp"
                  className="gallery-btn popup-image"
                >
                  <FaInstagram/>
                </a>
              </div>
            </div>
            <div className="col-auto">
              <div className="gallery-thumb">
                <img
                  src="assets/images/galleryviewimages/img4.webp"
                  alt="Gallery Image"
                />
                <a
                  href="assets/images/galleryviewimages/img4.webp"
                  className="gallery-btn popup-image"
                >
                  <FaInstagram/>
                </a>
              </div>
            </div>
            <div className="col-auto">
              <div className="gallery-thumb">
                <img
                  src="assets/images/galleryviewimages/img5.webp"
                  alt="Gallery Image"
                />
                <a
                  href="assets/images/galleryviewimages/img5.webp"
                  className="gallery-btn popup-image"
                >
                  <FaInstagram/>
                </a>
              </div>
            </div>
            <div className="col-auto">
              <div className="gallery-thumb">
                <img
                  src="assets/images/galleryviewimages/img6.webp"
                  alt="Gallery Image"
                />
                <a
                  href="assets/images/galleryviewimages/img6.webp"
                  className="gallery-btn popup-image"
                >
                  <FaInstagram/>
                </a>
              </div>
            </div>
            <div className="col-auto">
              <div className="gallery-thumb">
                <img
                  src="assets/images/galleryviewimages/img7.webp"
                  alt="Gallery Image"
                />
                <a
                  href="assets/images/galleryviewimages/img7.webp"
                  className="gallery-btn popup-image"
                >
                  <FaInstagram/>
                </a>
              </div>
            </div>
            <div className="col-auto">
              <div className="gallery-thumb">
                <img
                  src="assets/images/galleryviewimages/img8.webp"
                  alt="Gallery Image"
                />
                <a
                  href="assets/images/galleryviewimages/img8.webp"
                  className="gallery-btn popup-image"
                >
                  <FaInstagram/>
                </a>
              </div>
            </div>
            <div className="col-auto">
              <div className="gallery-thumb">
                <img
                  src="assets/images/galleryviewimages/img4.webp"
                  alt="Gallery Image"
                />
                <a
                  href="assets/images/galleryviewimages/img4.webp"
                  className="gallery-btn popup-image"
                >
                  <FaInstagram/>
                </a>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default GalleryThumbArea;
