import React, { useEffect } from "react";
import { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";
import GalleryThumbArea from "./GalleryThumbArea";
import Preloader from "./Preloader";
import Error from "./Error";
import Burger from "./Home_Sections/MenuArea_Sections.js/Burger";
import Wraps from "./Home_Sections/MenuArea_Sections.js/Wraps";
import Sides from "./Home_Sections/MenuArea_Sections.js/Sides";
import FriesAndShakes from "./Home_Sections/MenuArea_Sections.js/FriesAndShakes";
const Home = lazy(() => import('./Home'))
const AboutUs = lazy(() => import('./AboutUs'))
const ContactUs = lazy(() => import('./ContactUs'))

const App = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={
          <Suspense fallback={<Preloader />}>
            <Home />
          </Suspense>
        } />
        <Route path="/about-us" element={
          <Suspense fallback={<Preloader />}>
            <AboutUs />
          </Suspense>
        } />
        <Route path="/contact-us" element={
          <Suspense fallback={<Preloader />}>
            <ContactUs />
          </Suspense>
        } />
        <Route path="/menu/burger" element={
          <Suspense fallback={<Preloader />}>
            <Burger/>
          </Suspense>
        } />
        <Route path="/menu/wraps" element={
          <Suspense fallback={<Preloader />}>
            <Wraps />
          </Suspense>
        } />
        <Route path="/menu/sides" element={
          <Suspense fallback={<Preloader />}>
            <Sides />
          </Suspense>
        } />
        <Route path="/menu/fries-and-shakes" element={
          <Suspense fallback={<Preloader />}>
            <FriesAndShakes />
          </Suspense>
        } />
        <Route path="*" element={
            <Error />
        } />
      </Routes>
      <GalleryThumbArea />
      <Footer />
      <ScrollToTop/>
    </>
  );
};

export default App;
