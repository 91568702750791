import React, { useEffect, useState } from 'react';
import { FaArrowUp } from 'react-icons/fa';

const ScrollToTop = () => {
    const [pathLength, setPathLength] = useState(0);

    useEffect(() => {
        const scrollTopbtn = document.querySelector('.scroll-top');
        const progressPath = document.querySelector('.scroll-top path');
        const updateProgress = () => {
            const scroll = window.scrollY || document.documentElement.scrollTop;
            const height = document.documentElement.scrollHeight - window.innerHeight;
            const progress = pathLength - (scroll * pathLength / height);
            progressPath.style.strokeDashoffset = progress;
        };

        if (scrollTopbtn) {
            const pathLength = progressPath.getTotalLength();
            setPathLength(pathLength);
            progressPath.style.transition = 'none';
            progressPath.style.strokeDasharray = `${pathLength} ${pathLength}`;
            progressPath.style.strokeDashoffset = pathLength;
            progressPath.getBoundingClientRect();
            progressPath.style.transition = 'stroke-dashoffset 10ms linear';
            updateProgress();
            window.addEventListener('scroll', updateProgress);

            return () => {
                window.removeEventListener('scroll', updateProgress);
            };
        }
    }, [pathLength]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleScroll = () => {
        const scrollTopbtn = document.querySelector('.scroll-top');
        if (scrollTopbtn) {
            if (window.scrollY > 50) {
                scrollTopbtn.classList.add('show');
            } else {
                scrollTopbtn.classList.remove('show');
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="scroll-top" onClick={scrollToTop}>
            <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                <path d={`M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98`}
                    style={{
                        transition: 'stroke-dashoffset 10ms linear',
                        strokeDasharray: `${pathLength} ${pathLength}`,
                        strokeDashoffset: pathLength
                    }}
                />
            </svg>
        </div>
    );
};

export default ScrollToTop;
