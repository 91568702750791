import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { TiStarFullOutline, TiStarOutline } from "react-icons/ti";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useLocation } from 'react-router-dom';

const FriesAndShakes = () => {
    const [isHome, setIsHome] = useState(true);

    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/") {
            setIsHome(true)
        } else {
            setIsHome(false)
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }, 0);
    }, []);
    return (
        <>
            {isHome===false && <Helmet>
                <meta charSet="utf-8" />
                <title>Fries & Shakes - Menu - Burgitos</title>
                <meta name="description" content="BURGITOS is a premium fast-food establishment committed to crafting mouthwatering burgers using high-quality ingredients. Our diverse menu caters to various tastes, offering classic burgers, innovative chicken options, and vegetarian delights. With a focus on flavor and freshness, we strive to provide a satisfying culinary experience for burger enthusiasts. Visit us for a fusion of taste and quality in every bite!" />
            </Helmet>}
            {isHome === false && <h4 className="sec-title text-center mt-5">Fries and Shakes</h4>}
            <div className={`menu-tab-area space-bottom${isHome ? '':' container'}`}>
                <div className="food-menu-wrap">
                    <div className="food-menu">
                        <div className="food-menu_img">
                            <LazyLoadImage src="../../assets/img/update_2/menu/fries.jpg" style={{ borderRadius: '50%' }} alt="Menu" />
                        </div>
                        <div className="food-menu_content">
                            <h3 className="food-menu_title"> <a href="#">French Fries</a></h3>
                            <div className="product-rating">
                                <div className="star-rating" role="img" aria-label="Rated 4.00 out of 5">
                                    {/* <span style={{ width: '75%' }}>Rated <strong className="rating">4.00</strong> out of 5</span> */}
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarOutline className='text-danger fs-6' />
                                </div>
                                4K Reviews
                            </div>
                        </div>
                        <span className="food-menu_price">₹59</span>
                    </div>
                    <div className="food-menu">
                        <div className="food-menu_img">
                            <LazyLoadImage src="../../assets/img/update_2/menu/fries.jpg" style={{ borderRadius: '50%' }} alt="Menu" />
                        </div>
                        <div className="food-menu_content">
                            <h3 className="food-menu_title"> <a href="#">Peri Peri Cheesy Fries</a></h3>
                            <div className="product-rating">
                                <div className="star-rating" role="img" aria-label="Rated 4.00 out of 5">
                                    {/* <span style={{ width: '75%' }}>Rated <strong className="rating">4.00</strong> out of 5</span> */}
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarOutline className='text-danger fs-6' />
                                </div>
                                4K Reviews
                            </div>
                        </div>
                        <span className="food-menu_price">₹99</span>
                    </div>
                    <div className="food-menu">
                        <div className="food-menu_img">
                            <LazyLoadImage src="../../assets/img/update_2/menu/fries.jpg" style={{ borderRadius: '50%' }} alt="Menu" />
                        </div>
                        <div className="food-menu_content">
                            <h3 className="food-menu_title"> <a href="#">Cheesy Jalapeno Fries</a></h3>
                            <div className="product-rating">
                                <div className="star-rating" role="img" aria-label="Rated 4.00 out of 5">
                                    {/* <span style={{ width: '75%' }}>Rated <strong className="rating">4.00</strong> out of 5</span> */}
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarOutline className='text-danger fs-6' />
                                </div>
                                4K Reviews
                            </div>
                        </div>
                        <span className="food-menu_price">₹99</span>
                    </div>
                </div>

                <div className="food-menu-wrap">
                    <div className="food-menu">
                        <div className="food-menu_img">
                            <LazyLoadImage src="../../assets/img/update_2/menu/shakes.jpg" style={{ borderRadius: '50%' }} alt="Menu" />
                        </div>
                        <div className="food-menu_content">
                            <h3 className="food-menu_title"> <a href="#">French Vanilla Shake</a></h3>
                            <div className="product-rating">
                                <div className="star-rating" role="img" aria-label="Rated 4.00 out of 5">
                                    {/* <span style={{ width: '75%' }}>Rated <strong className="rating">4.00</strong> out of 5</span> */}
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarOutline className='text-danger fs-6' />
                                </div>
                                4K Reviews
                            </div>
                        </div>
                        <span className="food-menu_price">₹99</span>
                    </div>
                    <div className="food-menu">
                        <div className="food-menu_img">
                            <LazyLoadImage src="../../assets/img/update_2/menu/shakes.jpg" style={{ borderRadius: '50%' }} alt="Menu" />
                        </div>
                        <div className="food-menu_content">
                            <h3 className="food-menu_title"> <a href="#">Belgium Chocolate Shake</a></h3>
                            <div className="product-rating">
                                <div className="star-rating" role="img" aria-label="Rated 4.00 out of 5">
                                    {/* <span style={{ width: '75%' }}>Rated <strong className="rating">4.00</strong> out of 5</span> */}
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarOutline className='text-danger fs-6' />
                                </div>
                                4K Reviews
                            </div>
                        </div>
                        <span className="food-menu_price">₹99</span>
                    </div>
                    <div className="food-menu">
                        <div className="food-menu_img">
                            <LazyLoadImage src="../../assets/img/update_2/menu/shakes.jpg" style={{ borderRadius: '50%' }} alt="Menu" />
                        </div>
                        <div className="food-menu_content">
                            <h3 className="food-menu_title"> <a href="#">Signature Cold Coffee</a></h3>
                            <div className="product-rating">
                                <div className="star-rating" role="img" aria-label="Rated 4.00 out of 5">
                                    {/* <span style={{ width: '75%' }}>Rated <strong className="rating">4.00</strong> out of 5</span> */}
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarFullOutline className='text-danger fs-6' />
                                    <TiStarOutline className='text-danger fs-6' />
                                </div>
                                4K Reviews
                            </div>
                        </div>
                        <span className="food-menu_price">₹99</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FriesAndShakes
