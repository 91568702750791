import React from 'react'
import BreadCrumb from './Breadcrumb'
import { NavLink } from 'react-router-dom'
import { FaHome } from 'react-icons/fa'

const Error = () => {
    return (
        <>
            <BreadCrumb title="404 Error" />
            <section class="bg-auto space">
                <div class="container">
                    <div class="error-content">
                        <h2 class="error-title">Page Not Found</h2>
                        <p class="error-text">We're sorry, The page you are looking for on longer exists.</p>
                        <NavLink to="/" className="th-btn rounded-2" ><FaHome/>Back To Home</NavLink>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Error
